<template>
  <div>
    <my-header></my-header>
    <div class="companybox" :style="{}">
      <div class="company-header">
        <div class="company-header-top">
          <span
            :style="{
              fontSize: '14px',
              color: '#666',
              padding: '6px',
            }"
          >
            全部结果:
          </span>
          <span
            v-if="companyName"
            class="company-lable"
            v-text="`&quot;${companyName}&quot;`"
          >
          </span>
        </div>
        <div class="company-header-box" style="border-bottom: none">
          <div class="company-header-box-title">公司类型：</div>
          <div class="company-header-box-value">
            <span
              @click="lookcompanytype('')"
              :style="{
                color: type == '' ? '#e4393c' : ' #005aa0',
              }"
            >
              全部
            </span>
            <span
              @click="lookcompanytype(1)"
              :style="{
                color: type == 1 ? '#e4393c' : ' #005aa0',
              }"
            >
              厂商
            </span>
            <span
              @click="lookcompanytype(2)"
              :style="{
                color: type == 2 ? '#e4393c' : ' #005aa0',
              }"
            >
              客户
            </span>
            <span
              @click="lookcompanytype(3)"
              :style="{
                color: type == 3 ? '#e4393c' : ' #005aa0',
              }"
            >
              经销商
            </span>
            <span
              @click="lookcompanytype(4)"
              :style="{
                color: type == 4 ? '#e4393c' : ' #005aa0',
              }"
            >设计公司
              
            </span>
            <span
              @click="lookcompanytype(5)"
              :style="{
                color: type == 5 ? '#e4393c' : ' #005aa0',
              }"
            >施工单位
            </span>
            <span
              @click="lookcompanytype(6)"
              :style="{
                color: type == 6 ? '#e4393c' : ' #005aa0',
              }"
            >造价咨询公司
            </span>
          </div>
        </div>
        <div class="company-header-box" style="border-bottom: none">
          <div class="company-header-box-title">售卖类型：</div>
          <div class="company-header-box-value">
            <span
              @click="lookcompanytypes('')"
              :style="{
                color: selltype == '' ? '#e4393c' : ' #005aa0',
              }"
            >全部
            </span>
            <span
              @click="lookcompanytypes(1)"
              :style="{
                color: selltype == 1 ? '#e4393c' : ' #005aa0',
              }"
            >苗木
            </span>
            <span
              @click="lookcompanytypes(2)"
              :style="{
                color: selltype == 2 ? '#e4393c' : ' #005aa0',
              }"
            >砖石
            </span>
            <span
              @click="lookcompanytypes(3)"
              :style="{
                color: selltype == 3 ? '#e4393c' : ' #005aa0',
              }"
            >安装材料
            </span>
            <span
              @click="lookcompanytypes(4)"
              :style="{
                color: selltype == 4 ? '#e4393c' : ' #005aa0',
              }"
            >木艺/铁艺/塑胶
            </span>
            <span
              @click="lookcompanytypes(5)"
              :style="{
                color: selltype == 5 ? '#e4393c' : ' #005aa0',
              }"
            >户外家具
            </span>
            <span
              @click="lookcompanytypes(6)"
              :style="{
                color: selltype == 6 ? '#e4393c' : ' #005aa0',
              }"
            >辅材
            </span>
            <span
              @click="lookcompanytypes(7)"
              :style="{
                color: selltype == 7 ? '#e4393c' : ' #005aa0',
              }"
            >机械设备
            </span>
            <span
              @click="lookcompanytypes(8)"
              :style="{
                color: selltype == 8 ? '#e4393c' : ' #005aa0',
              }"
            >地材/机械台班
            </span>
          </div>
        </div>
        <div
          class="company-header-box"
          :style="{ height: pagination ? '200px' : '' }" 
        >
          <div class="company-header-box-title">品牌：</div>
          <div class="company-header-box-value">
            <span v-for="(v, k) of companyList" :key="k">
              <span
                :style="{
                  color: companyItem[0].id == v.id ? '#e4393c' : ' #005aa0',
                }"
                @click="lookcompany(v.id)"
                >{{ v.companyName }}</span
              >
            </span>
            <div class="pagin-box">
              <el-pagination
                v-if="pagination"
                background
                layout="prev, pager, next"
                :total="paginationNum"
                :page-size="pageSize"
                @current-change="handleCurrentChange"
                :hide-on-single-page="true"
              >
              </el-pagination>
            </div>
          </div>
          <div class="company-header-box-buttom">
            <el-button
              :style="{ marginTop: '15px' }"
              v-text="pagination ? '收起' : '更多'"
              @click="pagination = !pagination"
            ></el-button>
          </div>
        </div>
        
      </div>
      <!-- 页面初始显示 -->
      <div class="company-box" v-if="lookcompanyactive">
        <div class="company-box-item" v-for="(v, k) of companyList2" :key="k">
          <div class="company-box-item-img">
            <img :src="v.companyLogo" alt="" />
          </div>
          <div class="company-box-item-name">
            <span>{{ v.companyName }}</span>
          </div>
          <div class="company-box-item-button">
            <!-- <el-popover
              placement="left"
              width="300"
              trigger="hover"
              :model="visible">
              <el-form :model="form">
                <el-form-item label="请申请留言:">
                  <el-input type="textarea" v-model="form.text"></el-input>
                  <el-button type="primary" size="mini" @click="visible = false">确定</el-button>
                </el-form-item>
                
              </el-form>
              <span slot="reference" v-if="roleId == 2 || roleId == 3" type="text"  @click="authorisation(v.id)">申请授权</span>
            </el-popover> -->
            <!-- <el-dialog title="请您留言" :visible.sync="dialogFormVisible">
              <el-form :model="form">
                <el-form-item label="留言板:" label-width="120px">
                  <el-input v-model="form.text" ></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisibles(v.id)">确 定</el-button>
              </div>
              
            </el-dialog> -->
            <!-- <span v-if="roleId == 2 || roleId == 3" type="text"  @click="dialogFormVisible = true">申请授权</span> -->
            <span @click="tocertificate(v.companyUserId)">查看商家证书</span>
            <span v-if="v.type == 1 ||v.type==3" @click="lookcompany(v.companyUserId)">查看店铺商品</span>
          </div>
        </div>
      </div>
     
      <!-- 查看店铺商品显示 -->
      <div v-else>
        <div class="company-box-2">
          <div class="company-box-item" v-for="(v, k) of companyItem" :key="k">
            <div class="company-box-item-img">
              <img :src="v.companyLogo" alt="" />
            </div>
            <div class="company-box-item-name">
              <span>{{ v.companyName }}</span>
            </div>
            <div class="company-box-item-button">
              <span @click="tocertificate(v.companyUserId)">查看商家证书</span>
              <span v-if="v.type == 1 || v.type==3" @click="lookcompany(v.id)">查看店铺商品</span>
            </div>
          </div>
        </div>
      </div>
 <!-- <el-dialog title="申请留言" :visible.sync="authorisation">
  <el-form :model="form">
     <el-form-item label="留言" :label-width="formLabelWidth">
     <el-input v-model="form" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="authorisation = false">取 消</el-button>
    <el-button type="primary" @click="authorisation = false">确 定</el-button>
  </div>
</el-dialog> -->
      <!-- 商品详情 -->
      <div class="goodlist-list" v-if="companyListItem[0]">
        <div
          class="goodlist-list-box"
          v-for="(item, index8) in companyListItem"
          :key="index8"
        >
          <div class="goodlist-list-box-box" @click="toMessage(item)">
            <div class="goodlist-include-box">
              <img class="goodlist-list-img" :src="item.mainImage" alt="" />
            </div>
            <p class="goodlist-list-txt">{{ item.name }}</p>
            <div class="price-row">
              <span class="price-a">￥</span>
              <span class="price-b">{{ item.price }}</span>
              <span class="price-a">.00</span>
            </div>
            <div class="goodlist-bottom">
              <div class="goodlist-bottom-left" @click.stop="toMessage(item)">
                <span>查看详情</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <el-card
          class="box-card"
          v-if="!lookcompanyactive"
          style="margin-bottom: 30px"
          shadow="never"
        >
          <div class="text item">对不起,您所浏览的商品不存在。</div>
        </el-card>
      </div>
      <div class="pagin-box-item" v-if="companyListItem[0]">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="itempaginationNum"
          :page-size="itempageSize"
          @current-change="itemhandleCurrentChange"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
      <!-- 商品详情结束 -->
    </div>
  </div>
</template>
<script>
import myHeader from "../components/my-header.vue";
export default {
  inject: ["reload"],
  data() {
    return {
      //查看店铺商品切换显示状态
      lookcompanyactive: true,
      // 总页数
      paginationNum: "",
      // 分页显示隐藏
      pagination: false,
      // 商家列表
      companyList: [],
      // 页面只显示5个详细信息
      companyList2: [],
      // 点击详情显示一个商家信息
      companyItem: [{ id: "" }],
      // 商家旗下所有商品
      companyListItem: [],
      // 页面信息数据
      companyListpages: [],
      //  url传送的商家名称
      companyName: "",
      // 商家名称分页
      pageNum: 1,
      pageSize: 10,
      // 商家商品分页
      itempageNum: 1,
      itempageSize: 8,
      // 总页数
      itempaginationNum: "",
      // 记录当前点击的商家id
      itemid: "",
      type: "",
      selltype:"",
      
      // authorisation:false,
      form:{
        text:"",
      },
     dialogFormVisible: false,
    };
  },
  methods: {
    async getcompanys(selltype){
      console.log(selltype)
       if (selltype !== "" && selltype !== undefined) {
        selltype = `&sellType=${selltype}`;
      } else if (selltype == undefined) {
        selltype = "";
      } else {
        if (this.selltype != "" && selltype !== "") {
          selltype = `&sellType=${this.selltype}`;
        } else {
          selltype = "";
        }
      }
      console.log(this.$route.query.companyName);
      if (this.$route.query.companyName) {
        this.companyName = `&companyName=${this.$route.query.companyName}`;
      }
      const res = await this.$http({
        method: "get",
        url: `front/company/findList?pageNum=${this.pageNum}&pageSize=${this.pageSize}${this.companyName}&status=0${selltype}`,
      });
      console.log(res.data.data.list,'289');
      
      console.log(res.data.data.pages * this.pageSize);
      this.paginationNum = res.data.data.pages * this.pageSize;
      this.companyList2 = [];
      res.data.data.list.map((v, k) => {
        if (k < 5) {
          this.companyList2.push(v);
        }
      });
      console.log(this.companyList)
      this.companyListpages = res.data.data;
      this.companyList = res.data.data.list;
      
      },

    async getcompany(type) {
      console.log(type);
      if (type !== "" && type !== undefined) {
        type = `&type=${type}`;
      } else if (type == undefined) {
        type = "";
      } else {
        if (this.type != "" && type !== "") {
          type = `&type=${this.type}`;
        } else {
          type = "";
        }
      }
      console.log(this.$route.query.companyName);
      if (this.$route.query.companyName) {
        this.companyName = `&companyName=${this.$route.query.companyName}`;
      }
      const res = await this.$http({
        method: "get",
        url: `front/company/findList?pageNum=${this.pageNum}&pageSize=${this.pageSize}${this.companyName}&status=0${type}`,
      });
      console.log(res.data.data.list,'289');
      
      console.log(res.data.data.pages * this.pageSize);
      this.paginationNum = res.data.data.pages * this.pageSize;
      this.companyList2 = [];
      res.data.data.list.map((v, k) => {
        if (k < 5) {
          this.companyList2.push(v);
        }
      });
      console.log(this.companyList)
      this.companyListpages = res.data.data;
      this.companyList = res.data.data.list;
     
      // console.log(this.types,'303')
    },
    //
    //申请授权
    async dialogFormVisibles(id){
     this.dialogFormVisible = false;
     sessionStorage.setItem('parentCompanyId',id)
      const res = await this.$http({
        method: "post",
        url: `/front/authCompany/submit`,
        params:{
          parentCompanyId:id ,//String 被申请商铺id
          childCompanyId: sessionStorage.getItem('companyId'),//String 发起申请商铺id
          authMsg:this.form.text ,//String 申请留言
        }
      });

      if(res.data.status == 200){
        this.$message({
                message:"已发送申请",
                type: "success",
              });
      }else if(id == sessionStorage.getItem('companyId')){
        this.$message({
                message:"不能申请本人",
                type: "fail",
              });
      }else {
         return false;
      }
      console.log(id)
      console.log(sessionStorage.getItem('companyId'))
    },
    // 跳转证书详情页
    tocertificate(companyUserId) {
      sessionStorage.setItem("companyId", companyUserId);
      this.$router.push("certificate");
    },
    
    lookcompanytypes(selltype){
      this.getcompanys(selltype)
      this.selltype=selltype
    },
    //根据公司类型获取公司列表
    lookcompanytype(type) {
      this.getcompany(type);
      this.type = type;
    },
    // 改变当前页触发
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getcompany();
    },
    itemhandleCurrentChange(num) {
      this.itempageNum = num;
      this.lookcompany(this.itemid);
    },
    // 查看店铺商品
    async lookcompany(id) {
      this.itemid = id;
      this.lookcompanyactive = false;
      const res = await this.$http({
        method: "get",
        url: `front/company/findList?id=${id}`,
      });
      this.companyItem = res.data.data.list;
      console.log(this.companyItem)
      const data = await this.$http({
        method: "get",
        url: `front/product/findList?companyId=${id}&pageNum=${this.itempageNum}&pageSize=${this.itempageSize}`,
      });
      this.itempaginationNum = data.data.data.pages * this.itempageSize;
      this.itempageNum = 1;
      this.companyListItem = data.data.data.list;
      console.log(data.data.data);
      
    },
    // 去往详情页
    async toMessage(item) {
      const res = await this.$http({
        method: "get",
        url: "front/product/findById?id=" + item.id,
      });
      this.messageList = res.data.data;
      this.$router.push({
        path: "/goodMessage",
        query: { name: JSON.stringify(this.messageList) },
      });
    },
    async routeritem() {
      const query = this.$route.query;
      // console.log(JSON.parse(query.id));
      console.log(query.id);
      if (query.id === undefined) {
        console.log();
      } else {
        console.log(query);
        const id = query.id;
        this.lookcompany(id);
      }
    },
  },
  created() {
    this.getcompany();
    this.routeritem();
  },
  components: { myHeader },
   computed: {
    roleId() {
      return sessionStorage.getItem("roleId");
    },
  },
};
</script>
<style scoped>
.companybox {
  width: 1150px;
  margin: 0 auto;
  margin-top: 160px;
}
.company-lable {
  font-size: 14px;
  font-weight: 600;
  color: #666;
}
.company-header-box {
  position: relative;
  display: flex;
  height: 70px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #aaa;
}
.company-header-top {
  margin: 10px 0;
}
.company-header-box .company-header-box-title {
  display: inline-block;
  width: 120px;
  background: #ddd;
  color: #555;
  padding: 6px;
}
.company-header-box .company-header-box-value {
  flex: 18;
  display: flex;
  flex-wrap: wrap;
}
.company-header-box .company-header-box-value span {
  box-sizing: border-box;
  width: 14%;
  padding: 6px;
  color: #005aa0;
  height: 50%;
  cursor: pointer;
}
.company-header-box .company-header-box-buttom {
  flex: 2;
}
.pagin-box {
  position: absolute;
  bottom: 20px;
  display: inline-block;
  right: 50px;
}
.pagin-box-item {
  margin: 30px 0px 60px 700px;
}
.company-box {
  margin-bottom: 50px;
}
.company-box-item {
  display: flex;
  background: url("../assets/images/bg-shop.png");
  margin: 20px 0;
}
.company-box-item-img {
  height: 100px;
  width: 180px;
  padding: 10px;
}
.company-box-item-img img {
  height: 100%;
  width: 100%;
}
.company-box-item-button span {
  background: #e4393c;
  color: #fff;
  padding: 8px 25px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 120px;
  cursor: pointer;
  margin-right: 20px;
}
.company-box-item-name {
  width: 446px;
}
.company-box-item-name span {
  font-size: 14px;
  color: #333;
  line-height: 60px;
}
.goodlist-list {
  display: flex;
  /* flex: 1; */
  flex-wrap: wrap;
  width: 100%;
  min-width: 800px;
  /* height: 1500px; */
  margin: 0 auto;
  margin-top: 20px;
}
.goodlist-list-box {
  width: 23%;
  margin: 0 10px;
  min-width: 200px;
  /* height: 340px; */
}
.goodlist-list-box-box {
  width: 85%;
  margin: 10px;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 6px;
}
.goodlist-list-box-box:hover {
  box-shadow: darkgrey 0px 4px 19px 3px;
  /* height: 350px; */
}
.goodlist-include-box {
  width: 100%;
  height: 160px;
  margin: 0 auto;
  margin-top: 5%;
}
.goodlist-list-img {
  width: 100%;
  height: 180px;
  border-radius: 5px;
  /* margin-left: 22%;
    margin-top: 12%; */
}
.goodlist-list-txt {
  width: 90%;
  margin: auto;
  font-size: 13px;
  margin-top: 35px;
  margin-left: 15px;
}
.goodlist-list-txt:hover p {
  color: rgb(123, 123, 224);
}
.price-row {
  margin-top: 12px;
  margin-left: 5px;
}
.price-row span {
  color: #e4393c;
  font-size: 20px;
}
.goodlist-bottom {
  margin: 0 auto;
  display: flex;
  width: 70%;
  height: 50px;
  margin-top: 15px;
}
.goodlist-bottom:hover {
  border: 0.5px solid #df3033;
  border-radius: 4px;
}
.goodlist-bottom-left {
  border-radius: 4px;
  width: 1000%;
  height: 100%;
  background: #df3033;
  color: #fff;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.goodlist-bottom-left span {
  color: #fff;
}
.goodlist-bottom-left span {
  line-height: 50px;
}
.box-card {
  width: 75%;
  margin: 0 auto;
  text-align: center;
  height: 200px;
  margin-top: 20px;
}
.box-card div {
  color: #666;
  line-height: 150px;
}
</style>